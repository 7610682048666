import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  IAuthMe,
  IChangePassword,
  IInitiatePassword,
  ILogin,
  IProfile,
  IRegister,
  IRegisterResponse,
  ITokenResponse,
} from './interfaces/auth.interfaces';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { StateService } from '../../services/state.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly AUTH_TOKEN = 'auth-token';
  private readonly REFRESH_TOKEN = 'refresh-token';
  private readonly authEndpoint = 'auth';



  private registerEndpoint = `${this.authEndpoint}/register`;
  private loginEndpoint = `${this.authEndpoint}/login`;
  private currentUserEndpoint = `${this.authEndpoint}/me`;
  private passwordChangeEndpoint = `${this.authEndpoint}/initiate-password-change`;
  private changePasswordEndpoint = `${this.authEndpoint}/change-password-by-code`;
  private changePasswordLoggedEndpoint = `${this.authEndpoint}/change-password`;
  private authRefresh = `${this.authEndpoint}/refresh`;
  private authChatToken = `${this.authEndpoint}/chat-token`;

  public refreshToken!: string | null;


  public authMe!: IAuthMe | null;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private messageService: MessageService,
    private stateService: StateService
  ) {

  }


  public getApiChatToken(){
    return this.http.get<any>(this.authChatToken, { headers: { token: 'true' } })
  }
  
  public getCurrentUser(): Observable<IAuthMe> {
    return this.http.get<IAuthMe>(this.currentUserEndpoint, { headers: { token: 'true' } })
  }

  public refreshTokenStatic(refreshToken: any) {
      return this.http.post(this.authRefresh, { refreshToken: refreshToken },{ headers: { skipRefreshToken: 'true', token: 'true' } });
  }


  public checkIfTokenExpired(): boolean {

    if (this.platform.isBrowser) {
      const token = localStorage.getItem(this.AUTH_TOKEN);
      if (!token) {
        return false;
      }
      return !this.tokenExpired(token);
    } else {
      return false;
    }
  }


  public isAuthenticated(): boolean | any {
    if (this.platform.isBrowser) {
      const token = localStorage.getItem(this.AUTH_TOKEN);
      if (!token) {
        this.authMe = null;
        return false;
      } else {
        return true;
      }
    } else {
      this.authMe = null;
      return false;
    }

  }

  public tokenExpired(token: string | null):any {
    if(this.platform.isBrowser){
      if(token){
        try {
          const expiry = JSON.parse(window.atob(token.split('.')[1])).exp;
          return Math.floor(new Date().getTime() / 1000) >= expiry;
        } catch {
          console.log('CATCH ERROR')
          this.logout();
        }
      } else {
        return false;
      }
    
    } else {
      return false;
    }
  }

  public getToken(): string | null {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(this.AUTH_TOKEN);
    } else {
      return null;
    }
  }

  public setToken(val: string) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(this.AUTH_TOKEN, val);
    }
  }

  public setRefreshToken(val: string) {
    if (this.platform.isBrowser) {
      localStorage.setItem(this.REFRESH_TOKEN, val);
    }
  }

  public getRefreshToken(): string | null {
    if (this.platform.isBrowser) {
      const token = localStorage.getItem(this.REFRESH_TOKEN);
      if (!token) {
        return null;
      }

      // token can not be expired so now comment those :
      /*
      if (!this.tokenExpired(token) == false) {
        this.logout();
        this.authMe = null;
        if (this.platform.isBrowser) {
          // DO SOMETHING AFTER SESSION EXPIRED AND LOGGING OUT
        }
        this.messageService.add({ severity: 'warning', key:'notifications', summary: 'Session expired', detail: 'Please log in again' });
      }
      */
      return token;
    } else {
    return null;
    }
  }

  public logout() {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('auth-token');
      window.localStorage.removeItem('refresh-token');
    }
    this.router.navigate(['/auth/login'])
    this.stateService.setState('signedIn', false);
  }

  public register(registerForm: IRegister): Observable<IRegisterResponse> {
    return this.http.post<IRegisterResponse>(
      this.registerEndpoint,
      registerForm
    );
  }

  public login(loginForm: ILogin): Observable<ITokenResponse> {
    return this.http
      .post<ITokenResponse>(this.loginEndpoint, loginForm);
  }

  public updateCurrentProfile(body: IProfile){
      return this.http.put<IProfile>('profile', body, { headers: { token: 'true' } })
  }

  public initiatePasswordChange(email: IInitiatePassword) {
    return this.http.post(this.passwordChangeEndpoint, email);
  }

  public changePassword(newPassword: IChangePassword) {
    return this.http.post(this.changePasswordEndpoint, newPassword);
  }

  public changePasswordLogged(passwords: any) {
    return this.http.post(this.changePasswordLoggedEndpoint, passwords, {
      headers: { token: 'true' },
    });
  }
}
